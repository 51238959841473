import React, { FC } from 'react'

import { CloudinaryImage } from 'ethos-design-system'

interface HightlightWillsShortProps {}

const HightlightWillsShort: FC<HightlightWillsShortProps> = () => {
  let basicWillPlanPrice = 99
  let fullEstatePlanPrice = 199

  const basicWillPlanFeatures = [
    'Choose who will care for your children',
    'Decide who gets your assets',
    'Designate someone to settle debts',
    'Select a power of attorney',
    'Create a healthcare directive',
    'Authorize a caregiver to make medical decisions',
  ]

  const fullEstatePlanFeatures = [
    'Receive all documents included in the Legal Will plan',
    'Shield your assets with a trust',
    'Help your family avoid probate court',
    'Avoid unnecessary taxes',
  ]

  return (
    <div className="mx-auto max-w-[1140px] px-4 py-12 lg:py-[100px]">
      <h2 className="!mx-auto max-w-[724px] pb-6 text-center font-['Cambon'] text-[32px] font-bold lg:pb-9 lg:text-[60px]">
        Customize a plan that’s right for your family
      </h2>

      <div className="flex flex-col gap-10 lg:gap-16">
        <div className="flex flex-col items-center justify-center md:flex-row  md:gap-4 lg:justify-between lg:gap-0">
          <div>
            <CloudinaryImage
              className="max-w-[340px] lg:max-w-full"
              publicId="https://res.cloudinary.com/getethos/image/upload/v1724246413/man-with-child_wcwado.png"
              alt="Family"
              crop={CloudinaryImage.CROP_METHODS.FIT}
              height={[400, 400, 621, 621]}
              width={[400, 400, 600, 600]}
            />
          </div>

          <div className="mt-6 flex h-full max-w-[500px] flex-col lg:mt-20">
            <div>
              <div className="mb-3 font-bold uppercase tracking-[1.28px] text-emerald-100 lg:mb-8 lg:text-[24px] lg:tracking-[1.92px]">
                Protect what matters
              </div>
              <h3 className="pb-4 text-[24px] font-medium leading-[34px] tracking-[-0.28px] lg:pb-4 lg:text-[40px] lg:leading-[55px] lg:tracking-[-0.4px]">
                Create a will for ${basicWillPlanPrice}
              </h3>
              <ul className="m-0 flex flex-col gap-y-1 pl-7 text-base leading-[24px] tracking-[-0.28px] lg:gap-y-2 lg:pl-10 lg:text-[24px] lg:leading-[40px] lg:[&_li]:pl-3">
                {basicWillPlanFeatures.map((feature) => (
                  <li key={feature}>{feature}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="flex flex-col-reverse items-center justify-center md:flex-row md:gap-4 lg:justify-between lg:gap-0">
          <div className="mt-6 flex h-full max-w-[500px] flex-col lg:mt-20">
            <div>
              <div className="mb-3 font-bold uppercase tracking-[1.28px] text-emerald-100 lg:mb-8 lg:text-[24px] lg:tracking-[1.92px]">
                Even More Protection
              </div>
              <h3 className="pb-4 text-[24px] font-medium leading-[34px] tracking-[-0.28px] lg:pb-4 lg:text-[40px] lg:leading-[55px] lg:tracking-[-0.4px]">
                Create a will, trust, and more for ${fullEstatePlanPrice}
              </h3>
              <ul className="m-0 flex flex-col gap-y-1 pl-7 text-base leading-[24px] tracking-[-0.28px] lg:gap-y-2 lg:pl-10 lg:text-[28px] lg:leading-[40px] lg:[&_li]:pl-3">
                {fullEstatePlanFeatures.map((feature) => (
                  <li key={feature}>{feature}</li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            <CloudinaryImage
              className="max-w-[340px] md:bg-[transparent] lg:max-w-full"
              publicId="https://res.cloudinary.com/getethos/image/upload/v1724247199/wills-phone-199_upd9mv.png"
              alt="Family"
              crop={CloudinaryImage.CROP_METHODS.FIT}
              height={[350, 350, 546, 546]}
              width={[350, 350, 536, 536]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HightlightWillsShort
